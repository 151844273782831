import { Router } from 'aurelia-router';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { autoinject } from 'aurelia-dependency-injection';
import { ReportsHttpClient } from './../../../http_clients/ReportsHttpClient';
import { ReportsModel } from 'components/models/ReportsModel';
import { UsersHttpClients } from 'http_clients/UsersHttpClients';

@autoinject
export class Adminreport {
  private allInformationOfReport: Array<ReportsModel>

  constructor(private reportsHttpClient: ReportsHttpClient, private router: Router,

    private user: UsersHttpClients) {
    this.getAllSuggestionReports();
  }

  async getAllSuggestionReports() {
    try {
      let request = await this.reportsHttpClient.fetch('/reports/all');
      let is200Ok = await checkResponseStatus(request);
      this.allInformationOfReport = await is200Ok.json();

      this.allInformationOfReport.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB.getTime() - dateA.getTime();
      });

      console.log(this.allInformationOfReport);

      for (let i = 0; i < this.allInformationOfReport.length; i++) {
          console.log(this.allInformationOfReport[i].owner_id);

          try {
              let profile = await this.getProfile(this.allInformationOfReport[i].owner_id);
              console.log(profile.email);
              this.allInformationOfReport[i].email = profile.email;
              console.log(profile.email + ' deuxième');
          } catch (err) {
              console.log(err);
          }
      }
  } catch (err) {
      console.error('Erreur lors de la récupération des rapports:', err);
  }
}

  async getProfile(owner_id: string) {
    let request = await this.user.fetch('/users/' + owner_id)
    let is200Ok = await checkResponseStatus(request);
    return (await is200Ok.json());
  }
}
