import { Router } from "aurelia-router";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { autoinject } from "aurelia-dependency-injection";
import { ReportsHttpClient } from "../../../http_clients/ReportsHttpClient";
import { ReportsModel } from "components/models/ReportsModel";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { BetaUser } from "components/models/BetaUserModel";
import { json } from "aurelia-fetch-client";
import { User } from "components/models/UserModel";

@autoinject
export class AdminBetaRegister {
  private allUsers: User;
  private status: any;
  private email: string;
  private currentEmail: string;
  private allInformationOfUserToPatch: BetaUser = new BetaUser();

  constructor(
    private userhttpClient: UsersHttpClients,
    private router: Router
  ) {
    this.getAllUsers();
  }

  async getAllUsers() {
    let request = await this.userhttpClient.fetch("/users?limit=200");
    let is200Ok = await checkResponseStatus(request);
    this.allUsers = await is200Ok.json();
    console.log(this.allUsers);
  }

  /* async acceptBetaUser(index) {
    this.status = 'accepted';
    this.allInformationOfUserToPatch.email = this.allBetaRegister[index].email
    this.allInformationOfUserToPatch.status = this.status
    let request = await this.userhttpClient.fetch('/beta/patchbetauser', {
      method: 'PATCH',
      body: JSON.stringify(this.allInformationOfUserToPatch)
    })
    let is200Ok = await checkResponseStatus(request);
    let response = await is200Ok.json();
    Object.assign(this.allBetaRegister[index], response);
    this.sendBetaEmail(index);

    console.log("fini")
  }

  async refuseBetaUser(index) {
    this.status = 'refused';
    this.allInformationOfUserToPatch.email = this.allBetaRegister[index].email
    this.allInformationOfUserToPatch.status = this.status
    let request = await this.userhttpClient.fetch('/beta/patchbetauser', {
      method: 'PATCH',
      body: JSON.stringify(this.allInformationOfUserToPatch)
    })
    let is200Ok = await checkResponseStatus(request);
    let response = await is200Ok.json();
    Object.assign(this.allBetaRegister[index], response);

    console.log("fini")
  }

  async addThisAccountToResgister() {
    let request = await this.userhttpClient.fetch('/beta/register', {
      method: 'POST',
      body: JSON.stringify({email: this.email }) 
    })
    let is200Ok = await checkResponseStatus(request);
    let response = await is200Ok.json();
  }

  async sendBetaEmail(index){
    try{
      this.currentEmail = this.allBetaRegister[index].email
      let request = await this.userhttpClient.fetch('/beta/betaEmail', {
        method: 'POST',
        body: JSON.stringify({email : this.currentEmail})
      })
      let is200Ok = await checkResponseStatus(request);
      let reponse = await is200Ok.json();
    }catch(error){
    }
    let request = await this.userhttpClient.fetch('')
  } */
}
