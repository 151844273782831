import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { SingletonService } from "singleton";
import { AuthService } from "aurelia-auth";
import { User } from "components/models/UserModel";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { NewsletterHttpClients } from "http_clients/NewsletterHttpClients";
import { SubscriptionHttpClient } from "http_clients/SubscriptionHttpClient";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { ReportsHttpClient } from "http_clients/ReportsHttpClient";
import { ArticleHttpClient } from "http_clients/ArticleHttpClients";

@autoinject
export class Adminhome {
  private me: User;
  private reponseSubscription;
  private reponseNewsletter;
  private responseRegister;
  private responseSuggestion;
  private responseBlog;
  private responseBankReport;
  private totalNewsletter: number;
  private totalSubscription: number;
  private allUsers: User;
  private totalSuggestion: number;
  private totalBlog: number;
  private totalBankReport: number;

  constructor(
    private router: Router,
    private singleton: SingletonService,
    private userhttpclient: UsersHttpClients,
    private newsletter: NewsletterHttpClients,
    private subscritpion: SubscriptionHttpClient,
    private suggestion: ReportsHttpClient,
    private blogs: ArticleHttpClient
  ) {}

  activate() {
    this.me = this.singleton.getMe();
    let me = this.me;
    console.log(JSON.stringify({ me }) + " hello");

    this.getNewsLetter();
    this.getSubscribed();
    this.getRegister();
    this.getSuggestion();
    this.getBankReport();
  }

  goToNewsLetterInterface() {
    this.router.navigateToRoute("adminpage");
  }

  goToReportSuggestion() {
    this.router.navigateToRoute("adminreport");
  }

  goToBetaRegister() {
    this.router.navigateToRoute("adminbetaregister");
  }

  goToReportBank() {
    this.router.navigateToRoute("admin_report_bank");
  }

  goToReportTestPeerJs() {
    this.router.navigateToRoute("adminpeerserver");
  }

  goToAdminStats() {
    this.router.navigateToRoute("adminstats");
  }

  goToAdminBlog() {
    this.router.navigateToRoute("adminblog");
  }

  goToAdminSeeBlog() {
    this.router.navigateToRoute("adminseeblog");
  }

  async getNewsLetter() {
    let request = await this.newsletter.fetch("/newsLetters");
    let is200Ok = await checkResponseStatus(request);
    this.reponseNewsletter = await is200Ok.json();
    this.totalNewsletter = this.reponseNewsletter.length;
    console.log(this.totalNewsletter + "newsletter");
  }

  async getSubscribed() {
    let request = await this.subscritpion.fetch("/subscription/all");
    let is200Ok = await checkResponseStatus(request);
    this.reponseSubscription = await is200Ok.json();
    this.totalSubscription = this.reponseSubscription.length;
    console.log(this.totalSubscription + "subscription");
  }

  async getRegister() {
    let request = await this.userhttpclient.fetch("/users");
    let is200Ok = await checkResponseStatus(request);
    this.allUsers = await is200Ok.json();
  }

  async getSuggestion() {
    let request = await this.suggestion.fetch("/reports/all");
    let is200Ok = await checkResponseStatus(request);
    this.responseSuggestion = await is200Ok.json();
    this.totalSuggestion = this.responseSuggestion.length;
    console.log(this.totalSuggestion + "suggestion");
  }

  async getBankReport() {
    console.log("HELLO REPORT BANK ?");
    let request = await this.suggestion.fetch("/reportBank");
    let is200Ok = await checkResponseStatus(request);
    this.responseBankReport = await is200Ok.json();
    this.totalBankReport = this.responseBankReport.length;
    console.log(this.totalBankReport + "reportBank");
  }
}
