// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/logo_white.png", import.meta.url);
// Module
var code = `<template>
  <require from="./adminblog.scss"></require>
  <require from="../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="adminblog" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1>Redaction page</h1>
    </div>
    <div class="img_form flex-column" if.bind="isArticleSent == false">
      <div class="image flex-column">
        <img src="${___HTML_LOADER_IMPORT_1___}" ref="previewPic" click.delegate="changeBlogImage()">
        <a click.delegate="changeBlogImage()" i18n="savepot_add3">Choisir une image</a>
        <input value.bind="urlPic" ref="myInput" type="file" files.bind="selectedFile" accept="image/*"
          change.delegate="onSelectFile()" style="display: none;" />
      </div>
      <div class="spacer-16" if.bind="isArticleSent == false"></div>
      <form class="inputs flex-column" id="articleForm">
        <h4>Title</h4>
        <input placeholder="title here" value.bind="title" />
        <h4>Hashtags</h4>
        <input placeholder="Hashtags here" value.bind="hashtags" keyup.delegate="autoHashtags()" />
        <h4>Limace</h4>
        <input class="full" value.bind="slug" placeholder="limace"></input>
        <h4>Préface</h4>
        <textarea class="short" value.bind="short_content" placeholder="Short version"></textarea>
        <h4>Article complet</h4>
        <div class="visual_button flex-row" click.delegate="isVisualOn = true" if.bind="isVisualOn == false">
          <span class="visual">Prévisualiser</span>
        </div>
        <div class="visual_button flex-row" click.delegate="isVisualOn = false" if.bind="isVisualOn == true">
          <span class="visual" >Editing</span>
        </div>
        <textarea class="full" value.bind="content" placeholder="Actual content"
          if.bind="isVisualOn == false"></textarea>
        <p if.bind="isVisualOn == true" style="white-space: pre-wrap;" innerhtml.bind="mdConverter.makeHtml(content)"></p>
      </form>
    </div>
    <div class="flex-grow" if.bind="isArticleSent == true"></div>
    <animated-checkmark if.bind="isArticleSent == true"></animated-checkmark>
    <div class="flex-grow" if.bind="isArticleSent == true"></div>
    <button class="btn btn-primary" if.bind="isArticleSent == true" click.delegate="isArticleSent = false">
      <span i18n="write_new"></span>
    </button>
    <button click.delegate="sendPostArticleCall()" class="btn btn-primary" if.bind="isArticleSent == false">
      <span i18n="send"></span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;