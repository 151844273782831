// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./adminpeerserver.scss"></require>
  <section id="adminpeerserver" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateToRoute('home')" src="${___HTML_LOADER_IMPORT_0___}">
      <h3>
        Test Peer-To-Peer
      </h3>
    </div>
    <div>
      <button class="btn btn-primary" click.delegate="callOther()">Code P2P</button>
    </div>
    <div>
      <input type="text" value.bind="message">
      <button class="btn btn-primary" click.delegate="sendComment()">Envoyer un message</button>
    </div>

    <div repeat.for="msg of msgs">
      \${msg}
    </div>
  </section>
</template>
`;
// Exports
export default code;