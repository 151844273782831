import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { ArticleModel } from "components/models/ArticleModel";
import { FileUploadHttpClients } from "../../../http_clients/FileUploadHttpClients";
import { ArticleHttpClient } from "http_clients/ArticleHttpClients";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { AuthService } from "aurelia-auth";
import { json } from "aurelia-fetch-client";
import { I18N } from "aurelia-i18n";
import { ArticlesSearchModel } from "components/models/ArticlesSearchModel";

@autoinject
export class AdminEditBlog {
  private article: ArticleModel = new ArticleModel();
  private title: string;
  private short_content: string;
  private content: string;
  private img_url: string;
  private hashtags: string;
  private slug: string;
  private date: Date;
  private selectedFile;
  private myInput;
  private formData: FormData;
  private isLoading: boolean = false;
  private displayError = false;
  private errorMessage: string = "";
  private isArticleSent: boolean = false;
  previewPic: HTMLImageElement;
  private blogId;
  private blogToEdit: ArticlesSearchModel;
  private isVisualOn: boolean = false;
  private mdConverter;
  private showdown = require("showdown");

  constructor(
    private fileUploadHttpClients: FileUploadHttpClients,
    private articleHttpClients: ArticleHttpClient,
    private router: Router,
    private authService: AuthService,
    private i18n: I18N
  ) {
    this.mdConverter = new this.showdown.Converter();
  }

  activate(params) {
    this.blogId = params._id;
    this.getMyBlogById();
  }

  async getMyBlogById() {
    let request = await this.articleHttpClients.fetch(
      "/blog/filtered?_id=" + this.blogId
    );
    let is200ok = await checkResponseStatus(request);
    this.blogToEdit = await is200ok.json();
    console.log(this.blogToEdit);
  }

  async sendPostArticleCall() {
    let urlPic;
    try {
      urlPic = await this.uploadObjectiveImage();
      console.log(urlPic);
    } catch {
      urlPic = undefined;
      console.warn("pas d'image ou erreur");
    }

    this.errorMessage = "";
    this.displayError = false;
    this.isLoading = true;
    this.blogToEdit.docs[0].img_url = await urlPic;
    let httpRequest = await this.articleHttpClients.fetch(
      "/blog/" + this.blogId,
      {
        method: "PATCH",
        body: JSON.stringify({
          img_url: urlPic,
        }),
      }
    );
    let is200ok = await checkResponseStatus(httpRequest);
    let reponse = await is200ok.json();
  }

  changeBlogImage() {
    this.myInput.click();
    console.log("clicked");
  }

  onSelectFile() {
    console.log("selected");
    this.formData = new FormData();
    this.formData.append("upload", this.selectedFile[0]);

    // * actuellement file est de type blob
    // * il faut le convertir en URL
    // * pour pouvoir le mettre en attribut
    // * à notre image pour le preview
    const file = this.selectedFile[0];

    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.previewPic.src = reader.result.toString();
      });
      reader.readAsDataURL(file);
    }
  }

  async uploadObjectiveImage() {
    let httpResult = await this.fileUploadHttpClients.fetch("/fileupload/", {
      method: "POST",
      body: this.formData,
    });
    let isHttpOk = await checkResponseStatus(httpResult);
    let json_response = await isHttpOk.json();
    return json_response.url; // URL IS INSIDE THE JSON
  }

  autoHashtags() {
    if (this.hashtags.includes(" ")) {
    }
  }
}
