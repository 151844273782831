// Module
var code = `<template>
  <require from="./adminseeblog.scss"></require>
  <require from="../../../components/date-format-short"></require>
  <require from="../../../components/nav-bar/nav-bar"></require>
  <require from="../../../components/footer-bottom/footer-bottom"></require>
  <section id="adminseeblog" class="column">
    <nav-bar class="backdrop-blur"></nav-bar>
    <div id="blog_title" class="flex-column">
      <h3 t="blogs_subtitle"></h3>
      <h1 t="blogs_title"></h1>
    </div>
    <loader-ring if.bind="!lastBlog.docs[0]"></loader-ring>
    <div class="article_list flex-column">
      <div class="lastblog flex-column" if.bind="lastBlog.docs[0]">
        <i click.delegate="deleteBlog(lastBlog.docs[0]._id)" class="fas fa-times"></i>
        <div class="image" click.delegate="goToBlogEdit(lastBlog.docs[0]._id)">
          <img src.bind="lastBlog.docs[0].img_url">
        </div>
        <div class="container flex-column" click.delegate="goToBlogEdit(lastBlog.docs[0]._id)">
          <div class="title_hashtag flex-row">
            <h2>\${lastBlog.docs[0].title}</h2>
            <div class="flex-grow"></div>
            <p class="hashtag">#\${lastBlog.docs[0].hashtags}</p>
          </div>
          <p class="short_content">\${lastBlog.docs[0].short_content}</p>
          <div class="flex-grow"></div>
          <div class="date_more flex-row">
            <p class="date">\${lastBlog.docs[0].createdAt | dateFormatShort}</p>
            <div class="flex-grow"></div>
            <span class="more">Wanna see more?</span>
          </div>
        </div>
      </div>
      <h3 t="related_articles"></h3>
      <div class="related_articles">
        <div class="article flex-column" repeat.for="article of articles.docs"
          if.bind="lastBlog.docs[0].slug != article.slug">
          <i click.delegate="deleteBlog(article._id)" class="fas fa-times"></i>
          <div class="img_txt" click.delegate="goToBlogEdit(article._id)">
            <img src.bind="article.img_url || 'img/logo_white.png'">
          </div>
          <div class="container flex-column" click.delegate="goToBlogEdit(article._id)">
            <div class="title_hashtag flex-row">
              <h2>\${article.title}</h2>
              <div class="flex-grow"></div>
              <p class="hashtag">\${article.hashtags}</p>
            </div>
            <p class="short_content">\${article.short_content}</p>
            <div class="flex-grow"></div>
            <div class="date_more flex-row">
              <p class="date">\${article.createdAt | dateFormatShort}</p>
              <div class="flex-grow"></div>
              <span class="more">Wanna see more?</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer-default"></div>
    <footer-bottom></footer-bottom>
  </section>
</template>
`;
// Exports
export default code;