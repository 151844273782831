import { Router } from "aurelia-router";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { autoinject } from "aurelia-dependency-injection";
import { PeerToPeerHttpClients } from "../../../http_clients/PeerToPeerHttpClients";
import * as socketIo from "socket.io-client";
import { default as environment } from "../../../../config/environment.json";
import { DataConnection, Peer } from "peerjs";
import { SingletonService } from "singleton";
import { AuthService } from "aurelia-auth";
import { User } from "components/models/UserModel";
import { SponsorshipHttpClient } from "http_clients/SponsorshipHttpClient";
import { SponsorshipModel } from "components/models/SponsorshipModel";
import { RecipientsHttpClient } from "http_clients/RecipientsHttpClient";
import { FriendModel } from "components/models/FriendModel";
import io from "socket.io-client";

@autoinject
export class Adminpeerserver {
  private socket: socketIo.Socket;
  private peer: Peer;
  private message: String;
  private me: User;
  private friends: FriendModel;
  private call;

  private connection: DataConnection;
  private msgs: Array<string> = [];

  constructor(
    private peerToPeerHttpClients: PeerToPeerHttpClients,
    private singleton: SingletonService,
    private sponsorshipHttpClient: SponsorshipHttpClient,
    private recipientsHttpClient: RecipientsHttpClient,
    private router: Router
  ) {
    this.me = this.singleton.getMe();
  }

  activate() {
    this.peerConnect();
  }

  async peerConnect() {
    let userId = this.me._id;
    // Create a new Peer instance with a unique id
    this.peer = new Peer(userId, {
      config: {
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          {
            urls: "turn:numb.viagenie.ca:3478",
            username: "paul@debuck.info",
            credential: "Vossey08!",
          },
        ],
      } /* Sample servers, please use appropriate ones */,
    });

    let myWebRTCConnection;

    // Wait for the Peer instance to connect to the PeerJS server
    this.peer.on("open", async (id) => {
      console.log(`Connected as ${id}`);
      /*a: 63b5954ab4ad2c307c1e9338 */
      /*b: 63de4227e51b1944a4525323 */
      if (id == "63de4227e51b1944a4525323") {
        this.callOther("63b5954ab4ad2c307c1e9338");
      } else {
        this.callOther("63de4227e51b1944a4525323");
      }
    });

    // Handle incoming calls
    /*     console.log("trying to call"); */
    /*     this.peer.on("call", (call) => {
      console.log(`Received call from ${call.peer}`);
      myWebRTCConnection = call;
      call.answer();

      // Send the call object to the service worker
      navigator.serviceWorker.controller.postMessage({ call });
      console.log("Sent call object to service worker:", { call });
    }); */

    // Handle errors
    this.peer.on("error", (error) => {
      console.error(`Error: ${error}`);
    });
  }

  callOther(other: string) {
    const conn = this.peer.connect(other);
    conn.on("open", () => {
      console.log("Connection opened!");
      conn.send(`Hello ${other}!`);
      console.log(conn.dataChannel);
      console.log(
        "****************************************************************************************************************************"
      );
      console.log(conn);

      /* navigator.serviceWorker.controller.postMessage({ conn }); */

      /*       navigator.serviceWorker.ready
        .then((registration) => {
          registration.active.postMessage({
            type: "call",
            data: conn.dataChannel,
          });
        })
        .catch((error) => {
          console.error(
            "An error occurred while posting the message to the Service Worker:",
            error
          );
        }); */
    });

    /*   this.peer.on("connection", (conn) => {
      conn.dataChannel.onmessage(conn.dataChannel , )
      conn.on("data", (data) => {
        console.log("data");
        console.log(conn);
        console.log(`Received message: ${data}`);
      });
    }); */
  }
}
