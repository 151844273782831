import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { ArticleHttpClient } from 'http_clients/ArticleHttpClients';
import { AuthService } from 'aurelia-auth';
import { FileUploadHttpClients } from 'http_clients/FileUploadHttpClients';
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { I18N } from 'aurelia-i18n';
import { ArticleModel } from 'components/models/ArticleModel';
import { ArticlesSearchModel } from 'components/models/ArticlesSearchModel';

@autoinject
export class AdminSeeBlog {

    private articles: Array<ArticlesSearchModel> = [];
    private lastBlog: Array<ArticlesSearchModel> = [];

    constructor(private fileUploadHttpClients: FileUploadHttpClients,
        private articleHttpClients: ArticleHttpClient, private router: Router,
        private authService: AuthService, private i18n: I18N,) {

    }

    activate() {
        this.getAllBlogs()
        this.lastBlogCreated()
    }

    async getAllBlogs() {
        let request = await this.articleHttpClients.fetch('/blog/filtered')
        let is200ok = await checkResponseStatus(request)
        this.articles = await is200ok.json()

        console.log(this.articles)
    }

    goToBlogId(currentSlug: string) {
        console.log(currentSlug)
        this.router.navigateToRoute('blogsId', { slug: currentSlug })
    }

    goToBlogEdit(_id: string) {
        console.log(_id)
        this.router.navigateToRoute('admineditblog', { _id: _id })
    }

    async deleteBlog(_id: string) {
        let sure: boolean = confirm(this.i18n.tr("home.alerts.deleteBlog"));

        if (!sure) return;
        let request = await this.articleHttpClients.fetch('/blog/' + _id, {
            method: "DELETE",
        })
        let is200ok = await checkResponseStatus(request)
        let response = await is200ok.json()
        this.lastBlogCreated()
        this.getAllBlogs()
    }



    async lastBlogCreated() {
        let request = await this.articleHttpClients.fetch('/blog/filtered')
        let is200ok = await checkResponseStatus(request)
        this.lastBlog = await is200ok.json()

        console.log(this.articles)
    }


}
