// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./adminpage.scss"></require>
  <section id="adminpage" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateToRoute('adminhome')" src="${___HTML_LOADER_IMPORT_0___}">
    </div>
    <div class="title">
      <h1>
        Newsletter 📰
      </h1>
    </div>
    <div class="add">
      <br>
      <div>
        <input placeholder="Titre" id="email_title" type="text" value.bind="email_title" />
      </div>
      <div>
        <input placeholder="NewLetter" id="todayNewsLetters" type="text" value.bind="todayNewsLetters" />
      </div>
      <div class="sending">
        <button click.delegate="getAllNewsletterInscription()">get all newsletter</button>
      </div>
      <br>
      <div class="sending">
        <button click.delegate="sendToAllNewsLetter()">Send to newsletter</button>
      </div>
      <div>
      <br>
      <div class="sending">
        <button click.delegate="getAllUser()">get All User</button>
      </div>
      <div>
        <br>
        <div class="sending">
          <button click.delegate="sendToAllUser()">Send to all user</button>
        </div>
        <div>
          <br>
        Add someone:
        <div class="email_someone">
          <input placeholder="someone@hotmail.com" id="addsomeone" type="email" value.bind="email_news" />
          <button click.delegate="postNewsLetterEmail()">Add</button>
        </div>
      </div>
    </div>
    <div class="title">
      <h1>
        Users 🧑‍🤝‍🧑
      </h1>
    </div>
    <table>
      <tr>
        <th>Email</th>
        <th>Send</th>
      </tr>
      <tr repeat.for="currentUser of allInformationNewsLetter">
        <td>\${currentUser.email}</td>
        <td class="flex-row">
          <button class="btn" click.delegate="sendNewsLetterEmail(currentUser.email)">
            <i class="fas fa-check color-primary" id="check"></i>
          </button>
        </td>
      </tr>
      <!-- tr user -->
      <tr repeat.for="currentUser of allInformationUser">
        <td>\${currentUser.email}</td>
        <td class="flex-row">
          <button class="btn" click.delegate="sendNewsLetterEmail(currentUser.email, currentUser.displayName)">
            <i class="fas fa-check color-primary" id="check"></i>
          </button>
        </td>
      </tr>
    </table>
  </section>
</template>
`;
// Exports
export default code;