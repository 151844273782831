// Module
var code = `<template>
  <require from="./adminclient.scss"></require>
  <section id="adminclient" class="flex-column">
    <h3>
      ROOM PEER TO PEER
    </h3>
    <div>
      <input type="text" value.bind="message">
      <input type="text" value.bind="address">

      <button click.delegate="connect()">connect</button>

      <button click.delegate="sendComment()">envoyé un message</button>
      <button click.delegate="getAddress()">Get Address</button>
      <button click.delegate="sendTx()">Send Tx</button>
      <button click.delegate="getBalance()">get Balance</button>
      <button click.delegate="testNotification()">testNotification</button>

      <button click.delegate="myButton()">Send Pubkey</button>

      <button id="doIt">Try to conquer Italy!</button>
    </div>
    <div repeat.for="msg of msgs">
      \${msg}
    </div>
  </section>
</template>
`;
// Exports
export default code;