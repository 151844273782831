import { Router } from 'aurelia-router';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { autoinject } from 'aurelia-dependency-injection';
import { PeerToPeerHttpClients } from '../../../http_clients/PeerToPeerHttpClients'
import * as socketIo from 'socket.io-client';;
import { default as environment } from '../../../../config/environment.json'
import { DataConnection, Peer } from 'peerjs';
import { SingletonService } from "singleton";
import { AuthService } from "aurelia-auth";
import { User } from 'components/models/UserModel';
import { SponsorshipHttpClient } from 'http_clients/SponsorshipHttpClient';
import { SponsorshipModel } from 'components/models/SponsorshipModel';
import { RecipientsHttpClient } from 'http_clients/RecipientsHttpClient';
import { FriendModel } from 'components/models/FriendModel';
import { WalletsManager } from '../../../services/WalletManager.service';
import { PushService } from '../../../services/push.service';
import { NotificationHttpClient } from 'http_clients/NotificationHttpClient';



interface Msg {
  id: string,
  type: "getAddress" | "sendTx" | "comment" | "answer",
  args: any[]
}

const cfg = {
  defaultTimeout: 5000
}

@autoinject
export class Adminclient {
  private socket: socketIo.Socket;
  private peer: Peer;
  private message: String;
  private address: String;

  private me: User;
  private friends: FriendModel;
  private remotePeerId;
  private cbs: { [key: string]: any };


  private connection: DataConnection;
  private remote_connection: DataConnection;

  private msgs: Array<string> = [];




  constructor(private peerToPeerHttpClients: PeerToPeerHttpClients,
    private singleton: SingletonService,
    private sponsorshipHttpClient: SponsorshipHttpClient,
    private recipientsHttpClient: RecipientsHttpClient,
    private walletsManager: WalletsManager,
    private pushService: PushService,
    private notificationHttpClient : NotificationHttpClient

  ) {
    this.me = this.singleton.getMe()

    this.cbs = {}
  }


  activate(params) {
    this.remotePeerId = params.peer_id
    console.log(this.remotePeerId)
    this.getMyAddress().then(a => {
      this.address = a
    })

  }

  bind() {
    this.preparePeer();

  }

  /* test notification httpclient */
  async pushTestNotification(){
    let request = await this.notificationHttpClient.fetch('/sendNotification' , {
      method: 'POST',
      body: JSON.stringify({
        subscription: this.pushService.subscription,
        delay: 1,
        ttl: 0,
      }),
    })
    let is200ok = await checkResponseStatus(request)
    let response = await is200ok.json()
  }

  /* test notification */

  testNotification() {

    console.log("this.pushService",this.pushService);
    

    fetch(`http://localhost:3000/sendNotification`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        subscription: this.pushService.subscription,
        delay: 1,
        ttl: 0,
      }),
    });
  }

  myButton() {
    // let pubkey = this.getMyPubKey()

    this.handlePaymenRequest()
  }
  /**
   * sends a new payment request to the other peer connection
   */
  async sendPaymentRequest() {






  }
  async getBalance() {

    const w = this.getCurrentWallet()

    const balance = await w.getBalance()

    alert(balance.toString())





  }
  /**
   * Handles a payment request
   */
  async handlePaymenRequest() {

    const address = await this.getMyAddress()
    this.sendToOther(address);


  }
  /**
 * Gets the current wallet and the singleton current wallet selected index
 * generate a new public key for it and returns it
 */
  getCurrentWallet() {
    let allWallets = this.walletsManager.getAll()
    let selectedWalletIndex = this.singleton.getCurrentCryptoWalletIndex();

    const wallets = Array.from(allWallets.values())

    console.log("wallets", wallets, allWallets);


    if (!wallets[selectedWalletIndex]) {
      throw new Error(`could not find wallet.... index: ${selectedWalletIndex}`)
    }


    return wallets[selectedWalletIndex]
  }

  /**
   * Gets the current wallet and the singleton current wallet selected index
   * generate a new public key for it and returns it
   */
  async getMyAddress() {
    // let selectedWalletIndex = this.singleton.getCurrentCryptoWalletIndex();
    // let allWallets = this.walletsManager.getAll()
    // let wallets = Object.values(allWallets)
    let selectedWallet = this.getCurrentWallet()
    let address = await selectedWallet.getAddress();
    return address;
  }

  /**
   * Send the pubkey in param to the other peer
   */
  async sendToOther(address: string) {
    const w = this.getCurrentWallet()


    console.log("signing tx... ")
    const opts = {

      type: "normal" as const,
      to: address,
      amount: 500,
      opReturnData: ""
    }
    console.log("ARGS tx... ", opts)

    const tx = await w.signTx(opts)
    console.log("sending tx... ")

    const result = await this.sendMessge([tx], "sendTx")



  }



  answer(id: string, args: any[] = []) {


    const msg = {
      id,
      type: "answer",
      args
    }

    console.log("sending answer... ", msg);


    if (this.remote_connection) {
      this.remote_connection.send(msg);

    } else {
      this.connection.send(msg);

    }
  }
  async sendMessge(args: any, type = "comment") {
    console.log(this.message + 'send')
    const id = Math.random() * 10000
    const msg = {
      id,
      type,
      args
    }

    return new Promise((res, rej) => {

      if (this.remote_connection) {
        this.remote_connection.send(msg);

      } else {
        this.connection.send(msg);

      }
      this.cbs[id] = (r) => res(r)
      setTimeout(() => {
        rej(`did not get an anwser after ${cfg.defaultTimeout / 1000} seconds...`)
      }, cfg.defaultTimeout)
    })
  }
  connect() {

  }

  async getAddress() {

    const r = await this.sendMessge([], "getAddress")
    this.msgs.push(`address: ${r}`)


  }

  async sendTx() {

    const r = await this.sendMessge([], "getAddress") as string[]

    return this.sendToOther(r[0])



  }
  sendComment() {

    this.sendMessge([this.message])
    this.msgs.push(`moi: ${this.message}`)
    this.message = "";


  }


  preparePeer() {
    //Starting peerjs
    console.log("Starting local peer instance with my id: " + this.me._id);
    this.peer = new Peer(this.me._id, {
      debug: 1,
      config: {
        'iceServers': [
          { urls: "stun:openrelay.metered.ca:80", },
          {
            urls: "turn:openrelay.metered.ca:80",
            username: "openrelayproject",
            credential: "openrelayproject",
          },

          // { urls: 'stun:stun.l.google.com:19302' },
          // {
          //   urls: "turn:openrelay.metered.ca:443?transport=tcp",
          //   username: "openrelayproject",
          //   credential: "openrelayproject",
          // },
          // { urls: 'turn:numb.viagenie.ca:3478', username: 'paul@debuck.info', credential: 'Vossey08!' }
        ]
      } /* Sample servers, please use appropriate ones */
    })



    const listenToConnection = (conn) => {
      // someone want's to send me a tx and first asks for a new address from me
      let getAddress = async (data) => {

        console.log("call get address")
        const add = await this.getMyAddress()
        console.log("result: ", add)

        this.answer(data.id, [add])
      }
      // someone send me a comment
      let comment = (data) => {
        this.msgs.push("Other: " + data.args[0]);
        this.answer(data.id)

      }
      // someone sends me the final tx that I will process and finally send to
      // miners
      let sendTx = async (data) => {

        const w = this.getCurrentWallet()


        console.log(`broadcasting tx: `, data)


        //const r = await w.broadcast(data.args[0])     ///////comment parce que bug
        //console.log(`success broadcasting tx: `, r)


        this.answer(data.id)


      }
      let answer = (data) => {
        console.log("got answer... ", data);

        const cb = this.cbs[data.id]
        if (!cb) {
          console.error(`could not find cb for answer `, data)
          return
        }
        cb(data.args)
      }

      let cmds = {
        getAddress,
        comment,
        sendTx,
        answer
      }

      conn.on("data", async (data: Msg) => {
        if (!cmds[data.type]) {
          console.error(`cmd ${data.type} not found`);
          return
        }
        cmds[data.type](data)
      });
    }
    //Getting my friend's id


    //Connection starting
    this.peer.on("open", (id) => {
      console.log("My ID: ", id)
      // this.connection.send("hi!");
      const other = this.remotePeerId
      console.log("Me connecting to ", other)

      if (other) {
        this.connection = this.peer.connect(other);

        console.log("connection: ", this.connection)

        listenToConnection(this.connection)

        this.connection.on("open", () => {
          console.log("Connection successful")
        });
      }


    });



    //On Receive
    this.peer.on("connection", (conn) => {

      this.remote_connection = conn
      console.log("Somebody tries to connect to me");

      listenToConnection(this.remote_connection)


      this.remote_connection.on("open", () => {
        console.log("Connection to me successful")
      });
    });
  }

}
