import { Router } from 'aurelia-router';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { autoinject } from 'aurelia-dependency-injection';
import { NewsletterHttpClients } from './../../../http_clients/NewsletterHttpClients';
import { NewsLettersModel } from 'components/models/NewsLettersModel';
import { SearchNewsLettersModel } from 'components/models/SearchNewsLettersModel';
import { UsersHttpClients } from 'http_clients/UsersHttpClients';
import { json } from 'aurelia-fetch-client';
import { NotificationHttpClient } from 'http_clients/NotificationHttpClient';
import { User } from 'components/models/UserModel';

@autoinject
export class adminpage {
  private allInformationNewsLetter: NewsLettersModel[]
  private allInformationUser: User[]
  private totalNewsLetter: number;
  private totalUser: number;
  private currentEmail: string;
  private email_news: string;
  private todayNewsLetters: string = "banka.en.blockchain_education_event";
  private email_title: string = "Blockchain Education Event 3rd Edition Sept. 15th";
  private sendButtonText: string = "Send to all";

  constructor(private newsletterHttpClients: NewsletterHttpClients,
    private usershttpclients: UsersHttpClients,
    private router: Router,
    private notificationHttpClient: NotificationHttpClient) {
  }

  async getAllNewsletterInscription() {
    let request = await this.newsletterHttpClients.fetch('/newsLetters')
    let is200Ok = await checkResponseStatus(request);
    this.allInformationNewsLetter = await is200Ok.json();
    this.totalNewsLetter = this.allInformationNewsLetter.length
    console.log(this.totalNewsLetter)
  }

  async getAllUser() {
    let request = await this.usershttpclients.fetch('/users')
    let is200Ok = await checkResponseStatus(request);
    this.allInformationUser = await is200Ok.json();
    this.totalUser = this.allInformationUser.length
    console.log(this.totalUser)
  }





  async postNewsLetterEmail() {
    let httpRequest = await this.newsletterHttpClients.fetch('/newsletters', {
      method: 'POST',
      body: json({
        email: this.email_news
      })
    })
    let is200Ok = await checkResponseStatus(httpRequest);
    let reponse = await is200Ok.json();
    this.allInformationNewsLetter.push(reponse);
    return;
  }

  async sendToAllNewsLetter() {
    this.sendButtonText = "Sending newsletter...";
    for (let i = 0; i < this.allInformationNewsLetter.length; i++) {
      this.sendButtonText = "Sending " + (i + 1) + "/" + this.allInformationNewsLetter.length + ": "
        + this.allInformationNewsLetter[i].email;
      try {
        await this.sendNewsLetterEmail(this.allInformationNewsLetter[i].email);
      } catch (error) {
        this.sendButtonText = JSON.stringify(error)
        await setTimeout(() => { }, 1000);
      }
    }
  }
  async sendToAllUser() {
    this.sendButtonText = "Sending newsletter...";
    for (let i = 0; i < this.allInformationUser.length; i++) {
      this.sendButtonText = "Sending " + (i + 1) + "/" + this.allInformationUser.length + ": "
        + this.allInformationUser[i].email;
      try {
        await this.sendNewsLetterEmail(this.allInformationUser[i].email, this.allInformationUser[i].displayName );
      } catch (error) {
        this.sendButtonText = JSON.stringify(error)
        await setTimeout(() => { }, 1000);
      }
    }
  }

  async sendNewsLetterEmail(emailOfUser: string, displayName?) {
    try {
      this.currentEmail = emailOfUser;
      let request = await this.newsletterHttpClients.fetch('/newsletters/template', {
        method: 'POST',
        body: JSON.stringify({
          email: this.currentEmail,
          todayNewsLetters: this.todayNewsLetters,
          email_title: this.email_title,
          name: displayName
        })
      })
      let is200Ok = await checkResponseStatus(request);
      let reponse = await is200Ok.json();
    } catch (error) {

    }
    // let request = await this.newsletterHttpClients.fetch('')
    return;
  }


}
