// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/xmark.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./adminreport.scss"></require>
  <section id="adminreport" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateToRoute('adminhome')" src="${___HTML_LOADER_IMPORT_0___}">
      <h1>
        Contacts us
      </h1>
    </div>
    <div class="listing flex-column" repeat.for="index of allInformationOfReport">
      <div class="cards flex-column">
        <p>Type: \${index.type}</p><span class="right"><img src="${___HTML_LOADER_IMPORT_1___}" width="24" height="24" /></span>
        <p>Commentaire: \${index.comment}</p>
        <a href="mailto:\${index.email}">\${index.email}</a>
        
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;